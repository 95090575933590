import React, { Component, Fragment } from "react";
import { adminLabels } from 'redux/constant/admin-label-constant';
import { s3StaticPath } from 'helper/helperFunctions';
import $ from 'jquery';
import ReactPlayer from 'react-player';
// import screenfull from 'screenfull';
import { findDOMNode } from 'react-dom';
import VideoPreview from 'components/Upload/VideoPreview';

const previewStyle = {
	display: 'inline-block',
	width: 100,
	height: 100,
};

class VideoPreviewCustom extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		let stl = $(".imgContainer .prevImage").attr("style");
	}
	
	handleSetPlaybackRate = e => {
		this.setState({ playbackRate: parseFloat(e.target.value) })
	}

	handleClickFullscreen = () => {
		console.log('e',findDOMNode(this.player))
		// screenfull.request()
	}
	ref = player => {
		this.player = player
	}
	previewVideo = (src, name, index = null) => {
		return (

				<React.Fragment>
					<VideoPreview hideVideoControls={true} src={src} name={name} id={index}/>
					{
						(this.props.removeFile && index !== null) &&
						<div className="prevRemove">
							<span onClick={() => this.props.removeFile(this.props.type ? this.props.type : null, index)}>
								&times;
							</span>
						</div>
					}
				</React.Fragment>


		);
	}
	render() {
		const { filesToPreview } = this.props;
		// console.log('hahahh', filesToPreview);
		return (
			<div className="content">
				{
					filesToPreview ?
						
							<div className="uploadImages flexElem flexResponsive">
								{
									filesToPreview.map((file, index) => (
										this.previewVideo(file.preview, file.name, index)
									)
									)
								}
							</div> : null
							
				}
			</div>
		);
	};
}

export default VideoPreviewCustom;
