import { frontEndUserConstants } from "../constant/front-end-constant";
import User from "../../helper/User";
import { objectsConstants } from "../constant/objects-constant";

const initialState = {
  User: User,
  orderUploadInfo: {
    max_allowed_photos: {},
    max_allowed_videos: {},
    agency: {},
    photos_submitted_for_video_production: {},
    disable_traveler_for_upload: {},
    status: {},
    destination: {},
    preShippingContact: 0,
    approved_for_upload: {},
    finalize_by_qa:{},
    finalize_by_editor:{},
    approved_for_video_upload:{}
  },
  singleImage: {
    image_versions: {},
    image_format: {}
  },
  marketingFlyers: {
    traveler_flyer_version1: {},
    traveler_flyer_version2: {},
    before_departure_flyer: {}
  },
  progress: 0,
  shippingSettings: {},
  loginError: objectsConstants.LOGINERROR
};
export function frontEndUserReducer(state = initialState, action) {
  switch (action.type) {
    case frontEndUserConstants.LOGIN_FRONT_END_USER_REQUEST:
      return { ...state, loading: true };
    case frontEndUserConstants.LOGIN_FRONT_END_USER_SUCCESS:
      return {
        ...state,
        User: {
          ...action.payload,
          isAuthenticated: () => true
        }
      };
    case frontEndUserConstants.LOGIN_FRONT_END_USER_FAILURE:
      return { ...state, loading: false, loginError: action.payload };

    case frontEndUserConstants.FORGET_FRONT_END_USER_REQUEST:
      return { ...state, loading: true };
    case frontEndUserConstants.FORGET_FRONT_END_USER_SUCCESS:
      return { ...state, loading: false };
    case frontEndUserConstants.FORGET_FRONT_END_USER_FAILURE:
      return { loading: false };
    case frontEndUserConstants.LOGOUT_FRONT_END_USER:
      return { ...state };
    case frontEndUserConstants.GET_ORDER_UPLOAD_INFO_SUCCESS:
      return {
        ...state,
        orderUploadInfo: action.payload && action.payload.data && action.payload.data.settings ? { ...action.payload.data, ...action.payload.data.settings } : initialState.orderUploadInfo
      };
    case frontEndUserConstants.GET_SINGLE_IMAGE_SUCCESS:
      return {
        ...state,
        singleImage: action.payload.data
      };
    case frontEndUserConstants.GET_AGENCY_LOGO_SUCCESS:
    //console.log('action.payload.data', action.payload.data)
      return {
        ...state,
        agencyLogo: action.payload && action.payload.data && action.payload.data
      }
    case frontEndUserConstants.GET_AGENCY_LOGO_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case frontEndUserConstants.GET_AGENCY_RESOURCES_BY_ROLE_SUCCESS:
      return {
        ...state,
        marketingFlyers: action.payload.data
      }
    case frontEndUserConstants.GET_AGENCY_RESOURCES_BY_ROLE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case frontEndUserConstants.SHOW_PROGRESS:
      return {
        ...state,
        progress: action.payload
      }
    case frontEndUserConstants.RESET_PROGRESS:
      return {
        ...state,
        progress: 0
      }
    case frontEndUserConstants.GET_SHIPPING_SETTINGS_SUCCESS:
      return {
        ...state,
        shippingSettings: action.payload.settings
      }
    case frontEndUserConstants.GET_SHIPPING_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    default:
      return { ...state };
  }
}
