import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Dashboard} from 'layouts/Frontend/Dashboard/Dashboard';
import User from 'helper/User';
import {getOrder, updateTitlesMusic} from 'redux/actions/order-actions';
import {objectsConstants as OC} from 'redux/constant/objects-constant';
import Validator from 'simple-react-validator'
import {
    dateChangeHandler,
    inputChangeHandler,
    contactChangeHandler,
    selectChangeHandler,
    selectArrayChangeHandler,
    clean,
    toggleModal,
    s3StaticPath,
    convertMomentIntoDateObj
} from 'helper/helperFunctions';
import Dates from 'components/FrontEnd/Order/OrderForm/Dates';
import VideoTitles from 'components/FrontEnd/Order/OrderForm/VideoTitles';
import ShippingDetails from 'components/FrontEnd/Order/OrderForm/ShippingDetails';
import Comment from 'components/FrontEnd/Order/OrderForm/Comment';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import SampleMusic from 'components/FrontEnd/Order/SampleMusic';
import $ from 'jquery';
// import CreatableSelect from 'react-select/creatable';
import {getDestinations} from 'redux/actions/settings-actions';
import {storeDestination} from 'redux/actions/destination-actions';
import {Roles} from 'constants/Roles';
import GeneralInfo from 'components/FrontEnd/Order/OrderForm/GeneralInfo';
import {settingsService} from 'services/settings';
import {cloneDeep, forEach} from 'lodash';
import {orderService} from "../../../services/order";
import {confirmAlert} from "react-confirm-alert";
import Confirmalert from "../../ThemeComponents/confirmAlert";
import {getLatestCommentByCategoryTitle} from "../../../redux/actions/comment-actions";

var dateFormat = require('dateformat');
// import MyImage from 'components/Upload/MyImage';
const moment = require('moment');


const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
            setTimeout(() => {
                resolve(settingsService.getDestinationsAsync({inputValue: inputValue}));
            }, 1000);
        }
    );

class CustomizeTitles extends Component {
    constructor(props) {
        super(props)
        const {id} = this.props.match.params;
        this.state = {
            order_details: OC.ORDERDETAIL,
            pricing_details: OC.PRICINGDETAIL,
            shipping_details: OC.SHIPPINGDETAIL,
            production_details: this.props.production_details,
            order_id: id,
            showErrorMessages: false,
            submitComment: false,
            sampleMusicModal: false,
            comment: {
                created_at: null,
                updated_at: null,
                comment: ''
            },
            ptma_initial_country_France: false,
            vsa_initial_country_France: false,
        }
        if (User.hasRole(Roles.PHOTO_ADMIN)) {
            this.props.getOrderCall(User.order('id'));
        } else if (User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U])) {
            this.props.getOrderCall(this.props.match.params.id);
        }
        this.Validator = new Validator();
        this.dateChangeHandler = dateChangeHandler.bind(this);
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.updateContactHandler = contactChangeHandler.bind(this);
        this.selectUpdateHandler = selectChangeHandler.bind(this);
        this.selectArrayChangeHandler = selectArrayChangeHandler.bind(this);
        this.confirmAlert = React.createRef();
        this.clean = clean.bind(this);
        this.toggleModal = toggleModal.bind(this);
        this.props.getDestinations();
    }

    componentDidMount() {
        this.props.getLatestCommentByCategoryTitle('cancel', this.props.match.params.id).then(res => {
            this.setState({
                comment: res.data.comment
            })
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.order_details !== prevProps.order_details) {
            this.setState({
                order_details: this.props.order_details
            });
        }
        if (this.props.pricing_details !== prevProps.pricing_details) {
            this.setState({
                pricing_details: this.props.pricing_details
            });
        }
        if (this.props.shipping_details !== prevProps.shipping_details) {
            this.setState({
                shipping_details: this.props.shipping_details
            });
        }
        if (this.props.production_details !== prevProps.production_details) {
            this.setState({
                production_details: this.props.production_details
            });
        }
        if (this.props.shipping_details !== prevProps.shipping_details){

            if(this.props.shipping_details && this.props.shipping_details.pre_trip_material_address && this.props.shipping_details.pre_trip_material_address.country && this.props.shipping_details.pre_trip_material_address.country.title && this.props.shipping_details.pre_trip_material_address.country.title === 'France'){
                this.setState({
                    ptma_initial_country_France : true
                })
            }
            if (prevProps.shipping_details !== this.props.shipping_details && this.props.shipping_details.video_shipping_address && this.props.shipping_details.video_shipping_address.country && this.props.shipping_details.video_shipping_address.country.title && this.props.shipping_details.video_shipping_address.country.title === 'France') {
                this.setState({
                    vsa_initial_country_France : true
                })
            }
        }
    }

    getState = (state, container) => {
        this.setState(prevState => ({
            ...prevState,
            [container]: state
        }))
    }

    changeStatus = async (orderId, status, data) => {
        this.confirmAlert.current.props.onClosePro();
        await this.editUpdateOrder(data);
        orderService.updateOrderStatusByTitle(orderId, status)
    }

    closeConfirmAlert = (data) => {
        this.confirmAlert.current.props.onClosePro();
        this.editUpdateOrder(data);
    }

    confirmBox = (orderId, status, data, data_obj) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <Confirmalert
                        ref={this.confirmAlert}
                        header={`This order was cancelled`}
                        description={`This order was cancelled on ${(this.state.comment) ? moment(this.state.comment.created_at).format("dddd, MMMM Do YYYY, hh:mm:ss a") : ''}, would you like to reactive this order?`}
                        onClosePro={onClose}
                        extraHtml={() => {
                            return (
                                <span>
                                    comment: {(this.state.comment) ? this.state.comment.comment : ''} <br/>
                                    Group Name : {data.group_name} <br/>
                                    Order # {data.barcode} <br/>
                                </span>
                            );
                        }}
                        onNo={() =>
                            this.closeConfirmAlert(data_obj)
                        }
                        onYes={
                            () => this.changeStatus(orderId, status, data_obj)
                        }
                        yesLabel='Reactivate Order'
                        noLabel='No'
                        doNotAutoCloseForYes
                    />
                )
            }
        })
    }

    editUpdateOrder = async (data) => {
        let res;
        if (User.getProperty('order.id')) {
            res = await this.props.updateTitlesMusic(data, User.order('id'))
        } else {
            res = await this.props.updateTitlesMusic(data, this.props.match.params.id)
            this.props.history.push('/order-history');
        }
        this.setState({
            submitComment: true
        }, () => {
            this.setState({
                submitComment: false
            })
        });
        return res;
    }

    formSubmitHandler = async () => {
        try{
            if (this.state.shipping_details.pre_trip_material_address.country.title === 'France') {

                this.Validator.fields.state = true;
                this.Validator.errorMessages.state = null;
            }
            if (this.state.shipping_details.video_shipping_address.country.title === 'France') {

                this.Validator.fields['video address state'] = true;
                this.Validator.errorMessages['video address state'] = null;
            }
        }catch (e) {
            console.log("eeeeeee", e);
        }
        if (this.Validator.allValid()) {
            const data = {
                order_details: {...cloneDeep(this.state.order_details), agency_updating_order: 1},
                shipping_details: cloneDeep(this.state.shipping_details),
                production_details: cloneDeep(this.state.production_details)
            }
            forEach(data.order_details.order_dates, (item) => {
                if (item.value !== '')
                    item.value = dateFormat(convertMomentIntoDateObj(item.value), "yyyy-mm-dd HH:MM ")
            })

            if (this.props.order_details.status.title === 'Cancelled') {
                await this.confirmBox(this.props.order_details.id, 'Approved', this.props.order_details, data)
            } else {
                this.editUpdateOrder(data);
            }

        } else {
            this.Validator.showMessages();
            this.setState({
                showErrorMessages: this.Validator.messagesShown
            })
        }
    }

    selectChangeHandler = (selected, action) => {
        if (action.action === 'create-option') {
            if (action.name.match(/destinations/g) !== null) {
                const data = {
                    title: selected[0].label
                }
                this.props.storeDestination(data).then(
                    res => {
                        this.props.getDestinations();
                    }
                );
            }
        }
        if (action.action === 'remove-value') {
            this.selectArrayChangeHandler(selected, action);
        }
        if (action.action === 'select-option') {
            if (Array.isArray(selected)) {
                this.selectArrayChangeHandler(selected, action);
            } else {
                this.selectUpdateHandler(selected, action);
            }
        }
    }

    contactChangeHandler = (e) => {
        const {value, maxLength} = e.target;
        if (maxLength === value.length) {
            $(e.target).parent('div').next('div').find('input').focus();
        }
        this.updateContactHandler(e);
    }

    setStateOfParentPtma = (newValue) => {
        this.setState({
            ptma_initial_country_France: newValue
        });
    }
    setStateOfParentVsa = (newValue) => {
        this.setState({
            vsa_initial_country_France: newValue
            });
    }

    render() {
        // this.Validator.purgeFields();
        const {order_details, shipping_details, production_details, order_id, ptma_initial_country_France, vsa_initial_country_France} = this.state;
        // const { destinations } = this.props;
        // const groupLeaderPhone = order_details.group_leader_contact.phone1.split('-');
        let submited_for_production = false;
        // let received_date = false;
        submited_for_production = order_details.settings && Number( order_details.settings.photos_submitted_for_video_production.value) === 1 ? true: false;
        // received_date =
        //     production_details &&
        //     production_details.order_dates &&
        //     production_details.order_dates.receive_date &&
        //     production_details.order_dates.receive_date.value
        //         ? true
        //         : false;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {
                            User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) ?
                                <h2 className="panel_heading userLogin_heading">Order Information</h2>
                                :
                                <h2 className="panel_heading userLogin_heading">Customize Your Titles and Music</h2>
                        }
                    </div>
                </div>

                <div className="row mb-xlg">

                    <div className="col-md-12">
                        <div className="flexElem respFlex alignCenter spaceBetween">

                            <div className="text_16" style={{width: "100%", maxWidth: "660px"}}>
                                {
                                    User.hasRole(Roles.PHOTO_ADMIN) &&
                                    <React.Fragment>
                                        <span className="text-primary">Instructions:</span> Select any of the fields
                                        below to edit your order's information
                                        After making any desired changes, click the <span className="text-primary">"update order" button to save</span>
                                    </React.Fragment>
                                }
                            </div>
                            <div>
                                <img
                                    src={order_details.agency.agency_logo ? order_details.agency.agency_logo.file_path : s3StaticPath('img/company_logo_big.png')}
                                    alt="Logo name" style={{maxHeight: "127px"}}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        {/* <div className="form_tile">
                            <div className="form_tile_heading">
                                <img src={genInformation} alt="General Information" />
                                <span>General Information</span>
                            </div>

                            <div className="form_tile_content">
                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Agency
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        {order_details.agency.name}
                                    </div>
                                </div>
                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Group Name*
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        <input onChange={this.inputChangeHandler} name='order_details.group_name' type="text" autoComplete="off" value={order_details.group_name} placeholder="Group Name" className="form_tile_input block" />
                                        {this.Validator.message('group name', order_details.group_name, 'required')}
                                    </div>
                                </div>

                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        GL Phone*
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        <div className="flexElem flexThreeCols spaceBetween">
                                            <div>
                                                <input onChange={this.contactChangeHandler} maxLength='3' value={groupLeaderPhone[0]} name='order_details.group_leader_contact.phone1.0' type="text" autoComplete="off" placeholder="000" className="form_tile_input block" />
                                            </div>

                                            <div>
                                                <input onChange={this.contactChangeHandler} maxLength='3' value={this.clean(groupLeaderPhone[1])} name='order_details.group_leader_contact.phone1.1' type="text" autoComplete="off" placeholder="000" className="form_tile_input block" />
                                            </div>

                                            <div>
                                                <input onChange={this.contactChangeHandler} maxLength='4' value={this.clean(groupLeaderPhone[2])} name='order_details.group_leader_contact.phone1.2' type="text" autoComplete="off" placeholder="0000" className="form_tile_input block" />
                                            </div>
                                        </div>
                                        {this.Validator.message('Group Leader Contact', order_details.group_leader_contact.phone1, 'required')}
                                        {this.Validator.message('Group Leader Contact', order_details.group_leader_contact.phone1.replace(/-/g, '').length, 'min:10,num')}
                                    </div>
                                </div>
                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Destination*
                                    </div>
                                    <div className="col-md-8 text-primary">

                                        <CreatableSelect
                                            isMulti={true}
                                            value={returnLabelsWithValues(order_details.destinations, destinations)}
                                            options={destinations}
                                            onChange={this.selectChangeHandler}
                                            className="selectUser"
                                            name='order_details.destinations'
                                            placeholder="Select Destination"
                                        />
                                        <AsyncCreatableSelect
                                            isMulti={true}
                                            // cacheOptions
                                            defaultOptions
                                            defaultValue={order_details.destinationDefault}
                                            // defaultInputValue
                                            loadOptions={promiseOptions}
                                            onChange={this.handleChangeDestination}
                                            className="selectUser"
                                            placeholder="Select Destination"
                                        />
                                        {this.Validator.message('Destination', order_details.destinations, 'required')}
                                    </div>
                                </div>
                                <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                    <div className="col-md-4">
                                        Videos
                                    </div>
                                    <div className="col-md-8 text-primary">
                                        {order_details.program_type.video_quantity}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <GeneralInfo showErrorMessages={this.state.showErrorMessages}
                                     orderOptions={User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U])}
                                     validatorInstance={this.Validator} returnState={this.getState}
                                     order_details={this.props.order_details}
                                     ptma_initial_country_France={ptma_initial_country_France}
                                     vsa_initial_country_France={vsa_initial_country_France}
                                     />

                        {/* {
                            User.hasRole(Roles.PHOTO_ADMIN) &&
                            <Dates showErrorMessages={this.state.showErrorMessages} validatorInstance={this.Validator}
                                   returnState={this.getState} order_details={order_details}/>
                        } */}
                        <ShippingDetails showErrorMessages={this.state.showErrorMessages}
                                         validatorInstance={this.Validator} returnState={this.getState}
                                         shipping_details={shipping_details}
                                         order_details={this.props.order_details}
                                         setStateOfParentPtma={this.setStateOfParentPtma}
                                         setStateOfParentVsa={this.setStateOfParentVsa}
                                         ptma_initial_country_France={ptma_initial_country_France}
                                         vsa_initial_country_France={vsa_initial_country_France}
                                         />

                    </div>

                    <div className="col-md-6">
                        {
                            !submited_for_production &&
                            <React.Fragment>
                                <div className="form_tile">
                                    <div className="form_tile_heading">
                                        <img src={s3StaticPath('img/video_music.png')} alt="Video Music"/>
                                        <span>Video Music</span>
                                    </div>

                                    <div className="form_tile_content">
                                        <span>
                                            Your keepsake video will have destination instrumental music in the 
                                            background. Due to copyright laws custom music is not permitted.
                                        </span>
                                        {/* <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                            <div className="col-md-6">
                                                <div className="customChk">
                                                    <input onChange={this.inputChangeHandler}
                                                        checked={order_details.music_selection_criteria === 'destination_based'}
                                                        name='order_details.music_selection_criteria'
                                                        value='destination_based' type="radio" id="destinationBased"/>
                                                    <label htmlFor="destinationBased"
                                                        className="text_16">Destination-based</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="customChk">
                                                    <input checked={order_details.music_selection_criteria === 'age_based'}
                                                        onChange={this.inputChangeHandler} type="radio" id="ageBased"
                                                        name='order_details.music_selection_criteria' value='age_based'/>
                                                    <label htmlFor="ageBased" className="text_16">Age-based</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
                                            <div className="col-md-6 mt-md">
                                                <button onClick={() => {
                                                    this.toggleModal('sampleMusicModal')
                                                }} className="block themeBtn_14 blue_theme_button hover_dark">Sample
                                                    Music Here
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {
                                    order_details.program_type.title && order_details.program_type.title.includes('Video/Mozaix') &&
                                    
                                    <VideoTitles 
                                        showErrorMessages={this.state.showErrorMessages}
                                        validatorInstance={this.Validator}
                                        returnState={this.getState} 
                                        production_details={production_details}
                                        submited_for_production={submited_for_production}
                                        //  received_date={received_date}
                                    />
                                }
                            </React.Fragment>
                        }
                        {
                            User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
                            <Dates showErrorMessages={this.state.showErrorMessages} validatorInstance={this.Validator}
                                   returnState={this.getState} order_details={order_details}/>
                        }
                        <div className="form_tile">
                            <div className="form_tile_heading">
                                <img src={s3StaticPath('img/comments.png')} alt="Comments"/>
                                <span>Comments</span>
                            </div>

                            <div className="form_tile_content">
                                <div className="flexElem respFlex spaceBetween form_tile_row">
                                    <div className="col-md-12 text-primary">
                                        <Comment order_id={order_id} formType='edit'
                                                 submitComment={this.state.submitComment}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-2 col-md-offset-5 mt-xlg mb-xlg">
                        <button onClick={this.formSubmitHandler}
                                className="block themeBtn_14 blue_theme_button hover_dark">
                            Update Order
                        </button>
                    </div>
                </div>
                <ThemeModal size='lg' modalClass="frontEndModal" title='♫ Sample Music'
                            show={this.state.sampleMusicModal} hide={() => {
                    this.toggleModal('sampleMusicModal')
                }}>
                    <SampleMusic/>
                </ThemeModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {order_details, shipping_details, production_details} = state.ordersReducer;
    const {destinations} = state.settingsReducer;
    return {
        order_details,
        shipping_details,
        production_details,
        destinations
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderCall: (orderId) => {
            dispatch(getOrder(orderId))
        },
        updateTitlesMusic: (data, id) => {
            return dispatch(updateTitlesMusic(data, id))
        },
        getDestinations: () => dispatch(getDestinations()),
        storeDestination: (data) => {
            return dispatch(storeDestination(data))
        },
        getLatestCommentByCategoryTitle: (title, model_id) => {
            return dispatch(getLatestCommentByCategoryTitle(title, model_id))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(CustomizeTitles));
