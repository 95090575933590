import { SC } from "../helper/ServerCall";

export const orderService = {
    storeOrder,
    getOrdersPagination,
    getOrdersModeratorPagination,
    getOrderResourceFormats,
    getOrder,
    storeOrderResource,
    getOrderResources,
    getFolderOrderResources,
    deleteOrderResource,
    firstEmailNotReceived,
    getTripOrderUsers,
    getFollowingOrderUsers,
    getSpecialUser,
    deleteOrderUser,
    updateOrderUser,
    createSpecialUser,
    updateSpecialUser,
    approveOrderUpload,
    getOrderSettings,
    changeOrderHashTag,
    changeOrderMessaging,
    updateOrderOpenPhotoUpload,
    updateOrderMaxPhotoUpload,
    updateOrderAutoApprovePhotos,
    updateApproveOrderVideoUpload,
    updateOrderUploadCutOffDate,
    updateOrderUploadCutOffDateByEmail,
    updateShippingDetails,
    updateProjectCameraShipDate,
    updateOrderVideoTitles,
    toggleOrderEquipmentStatus,
    processShipStatus,
    updateOrderDetail,
    updatePricingDetail,
    processCamerasToShip,
    orderImages,
    getRequiredAssets,
    allOrderImagesByPhase,
    recentlyProcessedOrder,
    storeEditorOrderImages,
    changeImagePhase,
    assignUserToOrder,
    getImagePhase,
    updateOrderSettings,
    storeOrderImages,
    getOrderUploadInfo,
    getSingleImage,
    rotateImage,
    toggleImageStatus,
    changeImagesSortOrder,
    deleteImage,
    storePMOrderVideo,
    getOrderItems,
    storeKeepSakeOrder,
    getLPRData,
    receiveOrder,
    getAgencyFolderResources,
    getReOrders,
    getReOrder,
    getOrdersWithStatus,
    getCustomerServicesOrders,
    getOrderbyType,
    updateOrderDate,
    holdOrderAddComment,
    contactList,
    toggleInventoryStatus,
    documentGenerator,
    showOrdersFlyersList,
    addContactLogEntries,
    getPreAwarenessFlyersHistory,
    allowRegeneration,
    getOrderMosaic,
    changeMosaicImage,
    generateMosaicInstructions,
    getOrderMaterials,
    getOrderMosaics,
    updateOrderProshowFile,
    unprocessOrders,
    deleteOrder,
    OrdersTOMissingResouce,
    getPendingOrders,
    submitPendingOrders,
    updateOrderStatusByTitle,
    updateProgramType,
    videoTitlesByAgencyType,
    updateTravelerPassword,
    getPreShipmentEmailList,
    getPreDepartureList,
    getOrderCheckInList,
    orderCheckout,
    getOrderLatestComment,
    getCountOfImagesByFolder,
    getOrdersSearchByContactList,
    getOrderStars,
    replaceImage,
    getOrderShippingDetail,
    updateFinishByDate,
    allowReorderStatus,
    getAllowReorder,
    getOrderImagesPath,
    storeOrderImagesInDB,
    storeOrderImagesInDbByFrontEnd,
    mozaixProcessJobRecCall,
    getEditedOrderByUser,
    getProducedOrderByUser,
    updateIsInvoiced,
    getIsInvoiced,
    storeOrderNote,
    getReassignedBy,
    scheduledEstimatedOrder,
    uuidCall,
    getTtkPendingOrders,
    updateOrderSettingsForTtk,
    // emailTest
    getAllFollowingOrderUsers,
    getOrderChat,
    deleteMessages,
    submitVideoProductionFromEmail,
    publicDocumentToggle,
    toggleImageKeepsake,
    keepsakeDocumentToggle,
    deliverPtmStatusWtk,
    storeOrderVideosByFrontEnd,
    orderVideoListing,
    deleteVideos,
    toggleVideoKeepsake,
};

// function emailTest() {
//     return SC.getCall("notify2test");
// }
function firstEmailNotReceived(orderId) {
    return SC.postCall('firstEmailNotReceived/' + orderId);
}
function updateIsInvoiced(orderId, data) {
    return SC.postCall('updateIsInvoiced/' + orderId, data);
}
function storeOrder(orderData) {
    return SC.postCall("order", orderData);
}

function getOrdersPagination(page, filter, sort, pageSize, searchData) {
    return SC.postCall("getOrders?page=" + page + "&filter=" + filter + "&sort=" + sort + "&pageSize=" + pageSize, searchData);
}

function getOrdersModeratorPagination(page, filter, sort, pageSize, searchData, role) {
    if (role) {
        console.log("rolee existss");
        return SC.postCall("getOrdersModerator?page=" + page + "&filter=" + filter + "&sort=" + sort + "&pageSize=" + pageSize + "&role=" + role, searchData);
    } else {
        console.log("rolee dose not existss");
        return SC.postCall("getOrdersModerator?page=" + page + "&filter=" + filter + "&sort=" + sort + "&pageSize=" + pageSize, searchData);
    }
}

function getEditedOrderByUser(page, filter, sort, pageSize, id, order_ids) {
    return SC.postCall("getEditedOrderByUser?page=" + page + "&filter=" + filter + "&sort=" + sort + "&pageSize=" + pageSize + "&id=" + id + "&order_ids=" + order_ids);
}

function getProducedOrderByUser(page, filter, sort, pageSize, id, order_ids) {
    return SC.postCall("getProducedOrderByUser?page=" + page + "&filter=" + filter + "&sort=" + sort + "&pageSize=" + pageSize + "&id=" + id + "&order_ids=" + order_ids);
}

function getOrderResourceFormats(slug) {
    return SC.getCall(`getOrderResourceTypes/${slug}`);
}

function getOrder(orderId, LPR) {
    if (LPR !== null)
        return SC.getCall("order/" + orderId + "?LPR=" + LPR);
    return SC.getCall("order/" + orderId);
}

function storeOrderResource(resource, id) {
    return SC.postCall("storeOrderResource/" + id, resource);
}

function getOrderResources(orderId, type) {
    return SC.getCall(`getOrderResources/${orderId}/${type}`);
}

function getFolderOrderResources(orderId) {
    return SC.getCall(`getResources/${orderId}`);
}
function uuidCall(data) {
    return SC.getCallWithData('getResourcesWithUuid', data);
}
function getAgencyFolderResources(agencyId) {
    return SC.getCall(`getAgencyFolderResources/${agencyId}`);
}

function deleteOrderResource(id) {
    return SC.deleteCall("orderResourceDelete/" + id);
}

function getTripOrderUsers(id, page, filter, sort, pageSize) {
    return SC.getCall("getTripOrderUsers/" + id + "?page=" + page + "&filter=" + filter + "&sort=" + sort + "&pageSize=" + pageSize);
}

function getFollowingOrderUsers(id, page, filter, sort, pageSize) {
    return SC.getCall("getFollowingOrderUsers/" + id + "?page=" + page + "&filter=" + filter + "&sort=" + sort + "&pageSize=" + pageSize);
}

function getSpecialUser(orderId) {
    return SC.getCall("getSpecialOrderUser/" + orderId);
}

function mozaixProcessJobRecCall(orderId) {
    return SC.getCall("mozaixProcessJobRecCall/" + orderId);
}

function deleteOrderUser(id) {
    return SC.deleteCall("deleteOrderUser/" + id);
}

function updateOrderUser(id, userData) {
    return SC.putCall("updateOrderUser/" + id, userData);
}

function createSpecialUser(userData) {
    return SC.postCall("createSpecialUser", JSON.stringify(userData));
}

function updateSpecialUser(userData) {
    return SC.putCall("updateSpecialUser", userData);
}

function approveOrderUpload(id, value) {
    const data = { value: value };
    return SC.putCall("approveOrderUpload/" + id, data);
}

function getOrderSettings(id) {
    return SC.getCall("getOrderSettings/" + id);
}

function changeOrderHashTag(id, value) {
    const data = { value: value };
    return SC.putCall("changeOrderHashTag/" + id, data);
}

function changeOrderMessaging(id, data) {
    return SC.putCall("updateOrderMessaging/" + id, data);
}

function updateOrderOpenPhotoUpload(id, value) {
    const data = { value: value };
    return SC.putCall("updateOrderOpenPhotoUpload/" + id, data);
}

function updateOrderMaxPhotoUpload(id, value) {
    const data = { value: value };
    return SC.putCall("updateOrderMaxPhotoUpload/" + id, data);
}

function updateOrderAutoApprovePhotos(id, value) {
    const data = { value: value };
    return SC.putCall("updateOrderAutoApprovePhotos/" + id, data);
}

function updateApproveOrderVideoUpload(id, value) {
    const data = { value: value };
    return SC.putCall("updateApproveOrderVideoUpload/" + id, data);
}

function updateOrderUploadCutOffDate(id, value) {
    // const data = { value: value };
    return SC.putCall("updateOrderUploadCutOffDate/" + id, value);
}

function updateOrderUploadCutOffDateByEmail(id, value) {
    // const data = { value: value };
    return SC.putCall("updateOrderUploadCutOffDateByEmail/" + id, value);
}

function allowReorderStatus(id, data) {
    return SC.postCall('allowReorderStatus/' + id, data);
}

function updateShippingDetails(orderId, Data) {
    return SC.putCall("updateShippingDetails/" + orderId + "/update", Data);
}

function updateProjectCameraShipDate(orderId, Data) {
    return SC.putCall("updateOrderDate/" + orderId + "/update", Data);
}

function updateOrderVideoTitles(orderId, Data) {
    return SC.putCall("updateOrderVideoTitles/" + orderId + "/update", Data);
}

function toggleOrderEquipmentStatus(orderId, data) {
    return SC.putCall("toggleOrderEquipmentStatus/" + orderId + "/update", data);
}

function processShipStatus(orderId, data) {
    return SC.putCall("processShipStatus/" + orderId, data);
}

function updateOrderDetail(data, id) {
    return SC.putCall("order/" + id, data);
}

function updateFinishByDate(data, id) {
    return SC.postCall("updateFinishByDate/" + id, data);
}

function updatePricingDetail(data, id) {
    return SC.putCall("updatePricingDetails/" + id + "/update", data);
}

function processCamerasToShip(page, filter, sort, pageSize, brand_id, orders_type, advancedSearchFilters) {
    return SC.postCall(`camerasToShip?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}&brand_id=${brand_id}&orders_type=${orders_type}`, advancedSearchFilters);
}

function orderImages(orderId, version_type, tabName, page, filter, sort, pageSize, userRole) {
    return SC.postCall(`images/${orderId}/${version_type}/${tabName}?page=${page}&filter=${filter}&sort=${sort}&pageSize=${pageSize}`, { userRole });
}

function getRequiredAssets(orders) {
    return SC.postCall(`calculateRequiredAssets`, orders);
}

function allOrderImagesByPhase(orderId, version_type, tabName) {
    return SC.getCall(`allImagesByPhase/${orderId}/${version_type}/${tabName}`);
}

function recentlyProcessedOrder(page, filtered, sort, pageSize) {
    return SC.postCall(`recentlyProcessedCameras?page=${page}&filtered=${filtered}&sort=${sort}&pageSize=${pageSize}&agency_id=1`);
}

function storeEditorOrderImages(imagesData, orderId, folder, callback) {
    return SC.postCall(`storeOrderImages/${orderId}/${folder}`, imagesData, (response) => {
        callback(response);
    });
}

function storeOrderImagesInDB(imagesData, orderId, folder, callback) {
    return SC.postCall(`storeOrderImagesInDB/${orderId}/${folder}`, imagesData, (response) => {
        callback(response);
    });
}

function getOrderImagesPath(orderId, folder) {
    return SC.getCall(`getOrderImagesPath/${orderId}/${folder}`);
}


function replaceImage(imagesData, callback) {
    return SC.postCall(`replaceImage`, imagesData, (response) => {
        callback(response);
    });
}

function changeImagePhase(orderId, phase, bit) {
    return SC.getCall(`changeImagePhase/${orderId}/${phase}/${bit}`);
}

function getImagePhase(orderId, phase) {
    return SC.getCall(`getImagePhase/${orderId}/${phase}`);
}

function assignUserToOrder(orderId, data) {
    return SC.postCall(`assignUserToOrder/${orderId}`, data);
}

function submitVideoProductionFromEmail(orderId, data) {
    return SC.putCall(`submitVideoProductionFromEmail/${orderId}`, data);
}

function updateOrderSettings(orderId, data) {
    return SC.putCall(`updateOrderSettings/${orderId}`, data);
}

function updateOrderSettingsForTtk(orderId, data) {
    return SC.putCall(`updateOrderSettingsForTtk/${orderId}`, data);
}

function storeOrderImages(data, orderId, callback, source) {
    return SC.postCall(`storeOrderImages/${orderId}`, data, res => {
        callback(res);
    }, source);
}

function storeOrderImagesInDbByFrontEnd(data, orderId, callback, source) {
    return SC.postCall(`storeOrderImagesInDbByFrontEnd/${orderId}`, data, res => {
        callback(res);
    }, source);
}

function getOrderUploadInfo(orderId) {
    return SC.getCall(`getOrderUploadInfo/${orderId}`);
}

function getAllowReorder(id) {
    return SC.getCall('getAllowReorder/' + id);
}

function getSingleImage(imageId) {
    return SC.getCall(`getSingleImage/${imageId}`);
}

function rotateImage(imageId, direction, version_type) {
    return SC.putCall(`rotateImage/${imageId}`, { direction, version_type });
}

function toggleImageStatus(images) {
    return SC.putCall(`toggleImageStatus`, images);
}

function changeImagesSortOrder(sortOrder, images, order_id = null) {
    return SC.putCall(`changeImagesSortOrder`, { sortOrder, images, order_id });
}

function deleteImage(imageId, version_type) {
    return SC.getCall(`deleteImage/${imageId}/${version_type}`);
}

function storePMOrderVideo(videoData, orderId, folder, key, callback) {
    return SC.postCall(`storePMOrderVideo/${orderId}/${folder}/${key}`, videoData, (response) => {
        callback(response);
    });
}

function getOrderItems(orderId) {
    return SC.getCall(`getOrderItems/${orderId}`);
}

function storeKeepSakeOrder(data) {
    return SC.postCall(`storeKeepSakeOrder`, data);
}

function getLPRData(id) {
    return SC.getCall(`getLPRData/${id}`);
}

function receiveOrder(data) {
    return SC.postCall(`receiveOrder`, data);
}

function getReOrders(page, pageSize, filter, sort, orderType, orderId = '', reorder_type = '') {
    return SC.getCall(`getReOrders?page=${page}&pageSize=${pageSize}&filter=${filter}&sort=${sort}&orderType=${orderType}&orderId=${orderId}&reorder_type=${reorder_type}`);
}

function getReOrder(id) {
    return SC.getCall(`getReOrder/${id}`);
}

function getOrdersWithStatus(status, value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`getOrdersWithStatus/${status}/${value}?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
}

function getCustomerServicesOrders(data) {
    return SC.postCall(`getCustomerServiceOrderList`, data);
}

function getOrderbyType(type, pageSize, sort, orderType, filter, matchFilterWith) {
    return SC.postCall(`getOrderbyType?type=${type}&pageSize=${pageSize}&sort=${sort}&orderType=${orderType}&filter=${filter}`, { matchFilterWith });
}

function updateOrderDate(id, data) {
    return SC.putCall(`updateOrderDate/${id}/update`, data)
}

function holdOrderAddComment(id) {
    return SC.postCall(`holdOrderAddComment/${id}`)
}

function contactList(data) {
    return SC.postCall(`contactList`, data);
}

function toggleInventoryStatus(id, inv_barcode, extradata) {
    const data = { id, inv_barcode, settings: { ...extradata } }
    return SC.postCall(`toggleInventoryStatus`, data)
}

function documentGenerator(data) {
    return SC.postCall(`documentGenerator`, data)
}

function showOrdersFlyersList(data) {
    return SC.postCall(`showOrdersFlyersList`, data)
}

function addContactLogEntries(data) {
    return SC.postCall(`addContactLogEntries`, data)
}

function getPreAwarenessFlyersHistory(data) {
    return SC.postCall(`getPreAwarenessFlyersHistory`, data);
}

function allowRegeneration(id) {
    return SC.postCall(`allowRegeneration`, { id });
}

function getOrderMosaic(id) {
    return SC.getCall(`getOrderMosaic/${id}`);
}

function changeMosaicImage(data) {
    return SC.postCall(`changeMosaicImage`, data);
}

function generateMosaicInstructions(data) {
    return SC.postCall(`generateMosaicInstructions`, data);
}

function getOrderMaterials(id) {
    return SC.getCall(`getOrderMaterials/${id}`);
}

function getOrderMosaics(data) {
    return SC.postCall(`getOrderMosaics`, data);
}

function updateOrderProshowFile(orderId, data) {
    return SC.postCall(`updateOrderProshowFile/${orderId}`, data);
}

function unprocessOrders(data) {
    return SC.postCall(`unprocessOrders`, data);
}

function deleteOrder(orderId) {
    return SC.deleteCall("order/" + orderId);
}

function OrdersTOMissingResouce(data) {
    return SC.postCall(`orderTOMissing`, data);
}

function getPendingOrders(value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`pendingOrdersReport?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
}
function getTtkPendingOrders(value, fromDate, toDate, page, pageSize, sorted) {
    return SC.getCall(`ttkPendingOrdersReport?from=${fromDate}&to=${toDate}&page=${page}&pageSize=${pageSize}&sort=${sorted}`);
}

function submitPendingOrders(orders) {
    return SC.postCall(`submitPendingOrders`, orders);
}

function updateOrderStatusByTitle(orderId, status) {
    return SC.putCall(`updateOrderStatusByTitle/${orderId}`, { 'status': status });
}

function updateProgramType(orderId, programTypeId) {
    return SC.putCall(`updateProgramType/${orderId}`, { 'programType': programTypeId });
}

function videoTitlesByAgencyType(agency_type_id, order_id) {
    return SC.getCall(`videoTitlesByAgencyType?agency_type_id=${agency_type_id}&order_id=${order_id}`);

}

function updateTravelerPassword(orderId, data) {
    return SC.putCall(`updateTravelerPassword/${orderId}`, data);
}

function getPreShipmentEmailList(page, filter, sort, pageSize, orderType, matchFilterWith) {
    return SC.postCall(`getPreShipmentEmailList?page=${page}&pageSize=${pageSize}&filter=${filter}&sort=${sort}&orderType=${orderType}`, { matchFilterWith })
}

function getPreDepartureList(page, pageSize, sort, orderType, filter, matchFilterWith) {
    return SC.postCall(`getPreDepartureList?page=${page}&pageSize=${pageSize}&filter=${filter}&sort=${sort}&orderType=${orderType}`, { matchFilterWith })
}

function getOrderCheckInList(pageSize, sort, orderType, filter, matchFilterWith) {
    return SC.postCall(`getOrderCheckInList?pageSize=${pageSize}&sort=${sort}&orderType=${orderType}`, { matchFilterWith })
}

function orderCheckout(data) {
    return SC.postCall(`dopay/online`, data);
}

function getOrderLatestComment(data) {
    return SC.postCall(`getOrderLatestComment`, data);
}

function getCountOfImagesByFolder(orderId, folder) {
    return SC.getCall(`getCountOfImagesByFolder/${orderId}/${folder}`);
}

function getOrdersSearchByContactList(data) {
    return SC.postCall(`getOrdersSearchByContactList`, data);
}

function scheduledEstimatedOrder(data) {
    return SC.postCall(`scheduledEstimatedOrder`, data);
}

function getOrderStars(data) {
    return SC.postCall(`getOrderStars`, data);
}

export function deleteImages(data) {
    return SC.postCall(`deleteImages`, data);
}

function getOrderShippingDetail(order_id) {
    return SC.getCall(`getOrderShippingDetail/${order_id}`);
}
function getIsInvoiced(order_id) {
    return SC.getCall(`getIsInvoiced/${order_id}`);
}
function storeOrderNote(orderId, data) {
    return SC.postCall(`storeOrderNote/${orderId}`, data);
}

function getReassignedBy(orderId) {
    return SC.getCall(`getReassignedBy/${orderId}`)
}

function getAllFollowingOrderUsers(order_id) {
    return SC.getCall("getAllFollowingOrderUsers/" + order_id);
}

function getOrderChat(data) {
    return SC.postCall(`getOrderChat`, data);
}

function deleteMessages(data) {
    return SC.postCall(`deleteChatMessages`, data);
}

function publicDocumentToggle(orderId, data) {
    return SC.postCall(`publicDocumentToggle/${orderId}`, data);
}

function toggleImageKeepsake(images) {
    return SC.putCall(`toggleImageKeepsake`, images);
}

function keepsakeDocumentToggle(orderId, data) {
    return SC.postCall(`keepsakeDocumentToggle/${orderId}`, data);
}

function deliverPtmStatusWtk(orderId, status) {
    return SC.putCall(`deliverPtmStatusWtk/${orderId}`, { 'status': status });
}

function storeOrderVideosByFrontEnd(videoData, orderId) {
    return SC.postCall(`storeOrderVideosByFrontEnd/${orderId}`, videoData);
}
function orderVideoListing(orderId) {
    return SC.getCall(`orderVideoListing/${orderId}`)
}
function deleteVideos(data) {
    return SC.postCall(`deleteVideos`, data);
}
function toggleVideoKeepsake(data) {
    return SC.postCall(`toggleVideoKeepsake`, data);
}