import React, { Component } from 'react'
import {connect} from 'react-redux'
import User from 'helper/User';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import { getOrderUploadInfo } from 'redux/actions/order-actions';
import { orderService } from '../../../services/order';
import { Box, Grid } from "@mui/material"
import ReactPlayer from 'react-player';
import { alertActions } from '../../../redux/actions/alert-actions';
class Videos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            videos: [],
            selectedVideos: [],
        }
    }

    componentDidUpdate(prevProps, prevState) {

        // if (this.props.image.updated_at !== prevProps.image.updated_at) {
        //     const image = this.props.image;
        //     this.setState({
        //         path: image.file_path + image.file_title + "." + image.image_format.extension + '?updated_at=' + image.updated_at
        //     })
        // }
    }
    componentDidMount () {
        this.getVideos();
    }

    getVideos = () => {
        orderService.orderVideoListing(User.getProperty('order.id')).then((response) => {
            this.setState({ videos: response.order_videos.data, selectedVideos: [] });
            console.log("res", response);

        })
    }

    deleteSelected = () => {
        const videos = [];
        this.state.selectedVideos.forEach(item => {
            videos.push(this.state.videos[item].id);
        });
        const data = { videos }
        orderService.deleteVideos(data).then(
            res => {
                this.getVideos();
            }
        )
    }
    confirmImagesDelete = () => {
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmAlertForDelete typeName={`${this.state.selectedVideos.length > 1 ? `Videos` : `Video`}`} description={`Are you sure you want to delete ${this.state.selectedVideos.length > 1 ? `these videos` : `this video`}?`} onClosePro={onClose} deleteType={() => {
                this.deleteSelected()
            }} />
        })
    }


    ref = player => {
		this.player = player
	}

    selectImageHandler = (index) => {
        let selectedVideos = this.state.selectedVideos;
        if (selectedVideos.includes(index)) {
            selectedVideos = selectedVideos.filter(imageIndex => {
                return imageIndex !== index
            })
        } else {
            selectedVideos.push(index);
        }
        var selectedVideosSorted = selectedVideos.sort((a, b) => a - b);
        this.setState({
            selectedVideos: selectedVideosSorted
        })
    }
    toggleVideoKeepsake = (video) => {
        let status = 1 - video.is_approved_for_keepsake;
        console.log("id", video.id, "status", status);
        const data = {order_id: this.props.orderId, is_approved_for_keepsake: status, video_id: video.id};
        orderService.toggleVideoKeepsake(data).then(
            res => {
                this.getVideos();
                this.props.success(res.message);
                this.props.getOrderUploadInfoCall(this.props.orderId);
            }
        )
    }

    render() {
        const { selectedVideos, videos, status } = this.state;
        return (
            <section id="companyImagesInfo">
                <div className='container'>
                    {
                        <div className="text-center">
                            <button onClick={this.confirmImagesDelete} type='button' style={{ visibility: `${selectedVideos.length > 0 ? '' : 'collapse'}` }}
                                className="themeBtn_14 pl-xlg pr-xlg blue_theme_button hover_dark">
                                Delete Selected
                            </button>
                        </div>
                    }
                    <Grid container spacing={4} sx={{ py: 5 }}>
                        {
                            videos.map((video, i) => {
                                return (
                                    <Grid item xs={12} md={6} lg={3} key={i}>
                                        <Box className="galleryFix uploadedImageSize">
                                            <button type="button" onClick={() => {this.selectImageHandler(i) }}
                                                className={'delete_img hover_dark multiSelect' + (selectedVideos.includes(i) ? ' imgSelected' : ' multi_select')} style={{left:'1rem'}}>
                                                &nbsp;
                                            </button>
                                            <div
                                                className={"approvalChk hover_dark" + (video.is_approved_for_keepsake ? ' gray_gradient' : ' purple_blue_gradient')}>
                                                <div className="customChk">
                                                    <button onClick={(e) => {
                                                        this.toggleVideoKeepsake(video)
                                                    }} className={video.is_approved_for_keepsake ? "checkedBtn checkBtn text_14" : "checkBtn text_14"}
                                                            htmlFor={'image_' + video.id}>is keepsake
                                                    </button>
                                                </div>
                                            </div>
                                            <ReactPlayer
                                                ref={this.ref}
                                                key={video.video_url}
                                                url={video.video_url}
                                                className='react-player'
                                                width='false'
                                                height='false'
                                                controls={true}
                                                playbackRate={1.0}
                                                light={false}
                                            />
                                        </Box>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </div >
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    const {singleImage, orderUploadInfo} = state.frontEndUserReducer;
    return {
        singleImage,
        orderUploadInfo
    }
};

const mapDispatchToProps = dispatch => {
    return {
        
        getOrderUploadInfoCall: (orderId) => dispatch(getOrderUploadInfo(orderId)),
        success: (message) => { return dispatch(alertActions.success(message))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Videos)

